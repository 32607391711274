@import 'styles/variable.scss';
@import 'styles/mixins.scss';

.accountSettingWrapper {
  padding: 20px;
  height: 100%;
}

.navTitle {
  padding-bottom: 20px;
  border-bottom: 1px solid #e4e4e4;
  margin-bottom: 40px;

  color: #121212;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
