@import 'styles/variable.scss';
@import 'styles/mixins.scss';

.main {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 20px;

  &Nav {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e4e4e4;
    padding-bottom: 20px;
  }

  &Title {
    color: #121212;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  &Add {
    width: 26px;
    height: 26px;
    cursor: pointer;
  }

  &Content {
    // flex: 1;
    overflow: auto;
    height: calc(100% - 52px);
  }

  .loading {
    position: relative;
    height: 50vh;
  }
}

.company {
  &List {
    display: flex;
    align-items: center;
    overflow: hidden;
    flex-direction: column;
    padding-top: 20px;
  }

  &Item {
    padding: 18px 0;
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: space-between;
    width: 100%;

    border-bottom: 1px solid #e4e4e4;
  }

  &ItemTitle {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0.36px;
    flex: 1;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &ItemAction {
    color: #2378e5;
    text-align: right;
    margin-left: 10px;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    width: fit-content;
    white-space: nowrap;

    cursor: pointer;
  }
}

.modal {
  padding: 40px;
  color: #121212;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.17px;
  max-height: 50vh;
  overflow: auto;

  @include sm {
    padding: 20px;
  }

  i {
    font-style: normal;
  }

  p {
    margin: 0;
  }

  .title {
    padding-bottom: 30px;

    @include sm {
      font-size: 16px;
    }
  }

  .titleUnlock {
    font-weight: 700;
    line-height: 34px;

    @include sm {
      font-size: 16px;
      line-height: 22px;
    }
  }

  h6 {
    color: #515151;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.17px;
    margin-bottom: 30px;

    @include sm {
      font-size: 16px;
      margin-bottom: 15px;
    }
  }

  .list {
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #2378e5;
      font-size: 16px;

      span {
        width: 540px;
        font-size: 18px;
        margin-right: 20px;
        color: #121212;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      span,
      i {
        cursor: pointer;
        white-space: nowrap;
      }
    }

    :global {
      .MuiTypography-root {
        color: #121212;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.15px;
        flex: 1;
        width: 0;
      }

      .MuiFormControlLabel-root {
        cursor: default;
        width: 100%;
      }
    }
  }

  .search {
    display: flex;
    margin-bottom: 30px;

    $h5-height: 44px;
    $web-height: 60px;

    .searchInput {
      flex: 1;
      height: $web-height;

      :global {
        .MuiOutlinedInput-root {
          height: 100%;
          border-radius: 8px 0 0 8px;
        }

        .MuiInputLabel-root {
          top: 10px;

          @include sm {
            top: 2px;
          }
        }

        .MuiInputLabel-root.Mui-focused {
          top: 4px;
        }

        .MuiFormLabel-filled {
          top: 4px;
        }

        .Mui-focused .MuiOutlinedInput-notchedOutline {
          border-width: 1px;
        }
      }

      @include sm {
        height: $h5-height;
      }
    }

    .searchButton {
      text-transform: capitalize !important;
      background: #136fd3 !important;
      color: #fff;
      width: 127px;
      height: $web-height !important;
      border-radius: 0 7px 7px 0 !important;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 0.15px;

      &:hover {
        background: #1854a3 !important;
      }

      @include sm {
        width: 100px;
        height: $h5-height !important;
        font-weight: 400;
      }
    }

    .disabled {
      background: rgba(0, 0, 0, 0.23) !important;
      cursor: not-allowed;
      pointer-events: none;
    }
  }

  .tips {
    border: 1px dashed #e4e4e4;
    background: #fcfcfc;
    padding: 20px;

    h5 {
      color: #121212;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0.15px;
      margin-bottom: 30px;

      @include sm {
        font-size: 16px;
        margin-bottom: 10px;
      }
    }

    p {
      color: #515151;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0.135px;
      margin: 0;

      @include sm {
        font-size: 14px;
      }
    }
  }

  .searchLoading {
    position: relative;
    height: 15vh;
  }
}