@import 'styles/variable.scss';
@import 'styles/mixins.scss';

.main {
  margin-bottom: 20px;
  border-bottom: 1px solid #e4e4e4;

  .title {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0.36px;

    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .tip {
    color: #515151;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    margin-bottom: 10px;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .info {
    color: #121212;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .toolTip {
    font-size: 16px;
    color: #ffffff;
    max-width: 450px;
  }

  .image {
    margin-left: 10px;
  }

  .action {
    cursor: pointer;
  }
}

.modalContent {
  :global {
    .MuiInput-underline::before {
      border-bottom: 1px solid #e4e4e4;
    }

    .MuiInputLabel-root.Mui-focused {
      display: none;
    }

    .MuiInputLabel-root.MuiFormLabel-filled {
      display: none;
    }
  }

  .title {
    color: #121212;

    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0.36px;

    background: #f9f9f9;
    padding: 16px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .content {
    padding: 80px 40px;

    @include sm {
      padding: 20px 20px 40px 20px;
    }
  }

  .phoneInput {
    display: flex;
    align-items: baseline;

    @include sm {
      flex-direction: column;
    }
  }

  .smsCountry {
    width: 230px;
    margin-right: 20px;

    @include sm {
      width: 100%;
      margin-right: 0;
    }

    :global {
      .MuiOutlinedInput-root {
        border-radius: 8px;
      }

      .MuiInput-underline {
        padding-bottom: 0;
        line-height: 42px;
      }

      .MuiFormHelperText-root {
        margin: 0;
      }
    }
  }

  .smsInput {
    flex: 1;

    @include sm {
      width: 100%;
    }

    :global {
      .MuiInput-input {
        height: 44px;
      }

      .MuiInputLabel-root {
        top: 24px;
      }
    }
  }

  .sendOTP {
    display: flex;
    height: 54px;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    border: 0;
    outline: none;
    margin-left: 20px;

    border-radius: 8px;
    background: #136fd3;

    color: #ffffff;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    white-space: nowrap;

    cursor: pointer;

    @include sm {
      width: 100%;
      margin-top: 20px;
      margin-left: 0;
      height: 44px;
      font-size: 16px;
    }
  }

  .sendOTP.disabled {
    background: rgba(0, 0, 0, 0.23);
    cursor: not-allowed;
    pointer-events: none;
  }
}

.countdown {
  margin: 20px auto 0 auto;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1px;
  color: #86909c;
  text-align: center;

  .resendCode {
    color: #2379ea;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
