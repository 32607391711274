@import 'styles/variable.scss';
@import 'styles/mixins.scss';

.lineCamp {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  text-align: left;

  @include sm {
    text-align: center !important;
  }
}

.settings {
  &Container {
    @extend .GlobalContainer;
    margin: 16px auto;
    height: calc(100vh - 100px);
    min-height: calc(50vh);
    min-width: $NextAppMaxWidth;

    @include sm {
      margin: 0;
      padding: 0 !important;
      height: auto;
      min-width: auto;
    }
  }

  &Title {
    padding: 16px 14px;

    color: #121212;
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0.42px;

    @include sm {
      display: none;
    }
  }

  &Tabs {
    width: 183px;
    background: #fff;
    margin-right: 16px;
    border-radius: 8px;
    overflow: auto;

    :global {
      .MuiTabs-indicator {
        left: 0;
      }

      .MuiButtonBase-root {
        align-items: flex-start;
        text-transform: none;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .MuiButtonBase-root.Mui-selected {
        background: #fafafa;
      }
    }

    @include sm {
      width: 100%;
      margin-top: 10px;
      border-radius: 0;
      border-bottom: 1px solid #e4e4e4;

      :global {
        .MuiButtonBase-root.Mui-selected {
          background: transparent;
        }

        .MuiButtonBase-root {
          min-width: auto;
        }
      }
    }
  }

  &Panel {
    flex: 1;
    background: #fff;
    border-radius: 8px;
    overflow: auto;

    @include sm {
      border-radius: 0;
      width: 100%;
    }
  }

  &PanelItem {
    height: 100%;
  }
}