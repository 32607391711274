@import 'styles/variable.scss';
@import 'styles/mixins.scss';

.captcha {
  margin-top: 40px;

  @include sm {
    margin-top: 10px;
  }

  .codeBox {
    display: flex;
  }

  .itemContent {
    font-size: 20px;
    line-height: 54px;
    text-align: center;
    width: 100px;
    height: 60px;
    margin-right: 12px;
    background: #fff;
    border-bottom: 2px solid #e4e4e4;

    &.itemContentActive::before {
      position: absolute;
      top: 25%;
      left: 50%;
      display: block;
      width: 2px;
      height: 50%;
      margin-left: -1px;
      border: 1px solid #2378e5;
      animation: rc-captcha-input-flash steps(2) 1s infinite;
      content: ' ';
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .optErr {
    margin: 0;
    padding-top: 8px;
  }

  .InputBoxWrap {
    position: absolute;
    left: -9999px;
  }

  &.captchaThemeBox {
    .itemContent {
      color: #2378e5;
      border-bottom: 2px solid #e4e4e4;
      font-size: 32px;

      &.itemContentActive {
        position: relative;
        border-color: #2378e5;
      }

      &.itemContentHit {
        border-color: #2378e5;
      }
    }
  }

  @keyframes rc-captcha-input-flash {
    0% {
      visibility: hidden;
    }

    100% {
      visibility: visible;
    }
  }
}
